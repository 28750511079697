export default class CurrentPage {
  constructor() {
    this.init();
  }

  init() {
    const currentUrl = window.location.href;

    const currentLinks = document.querySelectorAll(
      'a[href="' + currentUrl + '"]'
    );
    currentLinks.forEach((link) => (link.className += ' current-page'));
  }
}
