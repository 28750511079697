export default class Filter {
  constructor(element) {
    this.element = element;
    this.listArticles = document.querySelectorAll('.wp-block-post');

    this.init();
  }

  init() {
    this.type = 'post';
    this.category = 'post';
    this.element.addEventListener('click', (event) =>
      this.filterContent(event)
    );
  }

  filterContent(event) {
    const buttonFilter = event.target.parentNode.getAttribute('data-filter');

    if (buttonFilter === 'type') {
      this.type = event.target.getAttribute('class');
      const children = event.target.parentNode.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.getAttribute('data-filter') === 'selected') {
          child.removeAttribute('data-filter');
        }
      }
      event.target.setAttribute('data-filter', 'selected');
    } else if (buttonFilter === 'category') {
      this.category = event.target.getAttribute('class');
      const children = event.target.parentNode.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.getAttribute('data-filter') === 'selected') {
          child.removeAttribute('data-filter');
        }
      }
      event.target.setAttribute('data-filter', 'selected');
    }

    this.listArticles.forEach((item) => {
      if (
        item.classList.contains(this.type) &&
        item.classList.contains(this.category) &&
        item.classList.contains('display-none')
      ) {
        item.classList.remove('display-none');
      } else if (
        !item.classList.contains(this.type) ||
        !item.classList.contains(this.category)
      ) {
        item.classList.add('display-none');
      }
    });

    this.checkAllHidden();
  }

  checkAllHidden() {
    let allHidden = true;
    const noArticle = this.element.querySelector('h2');
    for (let i = 0; i < this.listArticles.length; i++) {
      if (!this.listArticles[i].classList.contains('display-none')) {
        allHidden = false;

        break;
      }
    }

    if (allHidden) {
      noArticle.classList.remove('display-none');
    } else {
      if (!noArticle.classList.contains('display-none')) {
        noArticle.classList.add('display-none');
      }
    }

    return allHidden;
  }
}
