export default class Accordion {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    const accordions = document.getElementsByClassName('accordion');

    for (let i = 0; i < accordions.length; i++) {
      let btnAccordion = accordions[i].querySelector('.btn-accordion');
      btnAccordion.addEventListener('click', this.toggleAccordion);
      btnAccordion.addEventListener('keyup', this.toggleAccordion);
    }
  }

  toggleAccordion() {
    const addSection = this.closest('ul, .accordion');
    addSection.classList.toggle('active-section');
  }
}

//Code inspiré de la documentation sur freeCodeCamp, How to Build an Accordion Menu Using HTML, CSS and JavaScript : https://www.freecodecamp.org/news/build-an-accordion-menu-using-html-css-and-javascript/
