export default class LinkRewrite {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    const modifiedLink = this.element.href.replace('/eng', '');
    this.element.href = modifiedLink;
    console.log(this.element.href);
  }
}
