export default class Modal {
  constructor(element) {
    this.element = element;
    this.modalId = this.element.dataset.modalId;

    this.init();
  }

  init() {
    this.element.addEventListener('click', this.appendModal.bind(this));
  }

  appendModal() {
    const modalSection = document.querySelector(`#${this.modalId}`);
    console.log(modalSection);
    modalSection.classList.add('active-modal');
    document.documentElement.classList.add('body-active-modal');

    const modalClose = modalSection.querySelector('.modal-close');
    modalClose.addEventListener('click', this.closeModal.bind(this));
  }

  closeModal() {
    const modalSection = document.querySelector(`#${this.modalId}`);
    modalSection.classList.remove('active-modal');
    document.documentElement.classList.remove('body-active-modal');
  }
}
