export default class Number {
  constructor(target) {
    this.element = target;
    this.initNumber = parseInt(this.element.textContent);
    this.currentNumber = 0;
    this.increment = Math.ceil(this.initNumber / 100); // Adjust the increment based on the target number
    this.init();
  }

  init() {
    var interval = setInterval(() => {
      if (this.currentNumber >= this.initNumber) {
        clearInterval(interval);
      } else {
        this.currentNumber += this.increment;
        if (this.currentNumber > this.initNumber) {
          this.currentNumber = this.initNumber;
        }
        this.element.textContent = this.currentNumber;
      }
    }, 15);
  }
}
