export default class Header {
  constructor(element) {
    this.element = element;
    this.html = document.documentElement;
    this.initNavMobile();
    this.initMainNav();
  }

  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');

    toggle.addEventListener('click', this.onToggleNav.bind(this));
    toggle.addEventListener('keyup', this.onToggleNav.bind(this));
  }

  initMainNav() {
    const mainNav = document.querySelector('.js-main-nav');

    mainNav.addEventListener('focusout', () => {
      setTimeout(() => {
        if (!mainNav.contains(document.activeElement)) {
          this.html.classList.remove('nav-is-active');
        }
      }, 0);
    });
  }

  onToggleNav(event) {
    this.html.classList.toggle('nav-is-active');
  }
}
