export default class ProgressBar {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    window.addEventListener('scroll', () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrolled = scrollTop / (scrollHeight - clientHeight);

      this.element.style.width = `${scrolled * 100}%`;
    });
  }
}
