import Swiper from 'swiper/bundle';

/** Composante Carousel de Timtools */
export default class Carousel {
  /**
   * Méthode constructeur
   * @param {HTMLElement} element - Élément HTML sur lequel la composante est instanciée
   */
  constructor(element) {
    this.element = element;

    // Options par défaut pour la librairie Swiper
    this.defaultOptions = {
      slidesPerView: 1,
      grabCursor: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
      pagination: {
        el: this.element.parentNode.querySelector('.swiper-buttons-pagination'),
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: this.element.parentNode.querySelector('.swiper-button-next'),
        prevEl: this.element.parentNode.querySelector('.swiper-button-prev'),
      },
    };

    this.init();
  }

  /**
   * Méthode d'initialisation
   */
  init() {
    let options = this.defaultOptions;

    if (this.element.dataset.carousel == 'hero') {
      options = {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        preventClicks: true,
        speed: 1500,
        loop: true,
        effect: 'fade',
        autoplay: {
          delay: 5000,
        },
      };
    }

    if (this.element.dataset.carousel == 'center') {
      options = {
        grabCursor: true,
        effect: 'creative',
        creativeEffect: {
          prev: {
            shadow: true,
            translate: ['-120%', 0, -500],
          },
          next: {
            shadow: true,
            translate: ['120%', 0, -500],
          },
        },
        pagination: {
          el: this.element.parentNode.querySelector(
            '.swiper-buttons-pagination'
          ),
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: this.element.parentNode.querySelector('.swiper-button-next'),
          prevEl: this.element.parentNode.querySelector('.swiper-button-prev'),
        },
      };
    }

    // Instanciation d'un nouveau Swiper avec les options
    new Swiper(this.element, options);
  }
}
