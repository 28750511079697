export default class ColorForm {
  constructor() {
    this.init();
  }

  init() {
    if (window.location.pathname.endsWith('/devenir-membre/')) {
      const root = document.documentElement;
      root.style.setProperty('--color-complementary-form', '#0b6aa0');
    }
    if (window.location.pathname.includes('formation')) {
      const root = document.documentElement;
      root.style.setProperty('--color-complementary-form', '#d6410f');
    }
    if (window.location.pathname.endsWith('/portail/')) {
      const messageProtected = document.querySelector(
        '.swpm-partial-protection-msg'
      );
      messageProtected.classList.add('display-none');
    }
  }
}
